
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { listMedal, deleteMedal, saveMedal, searchMedalCategory } from "@/api/request/medal";

//组件
@Component({
  name: "MedalList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;
  private medalCategoryList: any = [];

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    id_str: "",
    medal_cat_id_str: "",

    //请求数据
    id: 0,
    name: "",
    medal_cat_id: 0,
  };

  //创建
  created() {
    //获取分类列表
    this.getCategoryList();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);
    this.listQuery.medal_cat_id = Number(this.listQuery.medal_cat_id_str);

    //获取数据
    const { data } = await listMedal(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //获取分类列表
  private async getCategoryList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await searchMedalCategory({ name: "", page_size: 200 });

    //数据赋值
    this.medalCategoryList = data.list;
    this.medalCategoryList.unshift({ id: 0, name: "请选择分类列表" });
  }

  //处理创建
  private handleCreate(): void {
    //初始化表单
    this.addForm = {
      //基本信息
      id: 0, //勋章ID
      sort: 0, //排列顺序
      icon: "", //勋章图片
      medal_cat_id: 0, //勋章分类ID

      //名称列表
      name_list: {
        zh: "",
        en: "",
        id: "",
      },
    };
    this.picUrl = null;
    this.picFileList = [];
    this.dialogStatus = "create";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //初始化表单
    this.addForm = {
      //基本信息
      id: row.id, //勋章ID
      sort: row.sort, //排列顺序
      icon: row.icon, //勋章图片
      medal_cat_id: row.medal_cat_id, //勋章分类ID

      //名称列表
      name_list: row.name_list,
    };
    this.picUrl = null;
    this.picFileList = [];
    if (GFunc.checkstring(row.icon)) {
      this.picUrl = GFunc.checkstring(row.icon);
      this.picFileList.push({ name: "name.jpg", url: GFunc.checkstring(row.icon) });
    }
    this.dialogStatus = "update";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    this.$confirm(`确定删除勋章？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求删除
        const res: any = await deleteMedal({ id: row.id });

        //删除成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("删除成功");

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private titleMap = { update: "编辑勋章", create: "新增勋章" };

  //添加表单
  private addForm: any = {
    //基本信息
    id: 0, //勋章ID
    sort: 0, //排列顺序
    icon: "", //勋章图片
    medal_cat_id: 0, //勋章分类ID

    //名称列表
    name_list: {
      zh: "",
      en: "",
      id: "",
    },
  };

  //创建勋章
  private async createData() {
    //判断返回
    if (!this.picUrl) return Message({ message: "请上传勋章图片！", type: "error", duration: 5 * 1000 });
    if (this.addForm.medal_cat_id == 0) return Message({ message: "请上选择勋章分类", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入勋章中文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入勋章英文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入勋章印尼语名称！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.icon = this.picUrl;
    this.addForm.sort = Number(this.addForm.sort);

    //添加
    await saveMedal(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //更新勋章
  private async updateData() {
    //判断返回
    if (this.addForm.icon == "") return Message({ message: "请上传勋章图片！", type: "error", duration: 5 * 1000 });
    if (this.addForm.medal_cat_id == 0) return Message({ message: "请上选择勋章分类", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入勋章中文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入勋章英文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入勋章印尼语名称！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort);

    //添加
    await saveMedal(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //--------------------------------- 图片处理 ---------------------------------
  //定义变量
  private picUrl: any = null;
  private picFileList: any[] = [];
  private getHeads = getHeaders();
  private getAction = this.getApi();

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片动画预览
  private handlePicPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //上传文件
  private handlePicBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 10;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出10M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图片
  private handlePicRemove(file: any, fileList: any): void {
    this.picUrl = null;
    this.picFileList = [];
  }

  //上传图片成功
  private handlePicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picFileList = fileList;
    this.picUrl = response.data.images[0];
  }

  //--------------------------------- 显示图片 ---------------------------------
  //定义变量
  private dialogImageVisible = false;
  private dialogImageUrl: any = null;

  //打开图片
  private openImg(url: string) {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }
}
